import React from 'react'

function Mails() {
  return (
    <>
        <div>
            <h3>Ventas</h3>
            <p>ventas@famox.com.ar</p>
        </div>
        <div>
            <h3>Atención al Cliente</h3>
            <p>atencion.cliente@famox.com.ar</p>
        </div>
        <div>
            <h3>Administración</h3>
            <p>info@famox.com.ar</p>
        </div>
    </>
  )
}

export default Mails