import React from 'react'

function Telefono() {
  return (
    <div>
        <h3>Teléfono</h3>
        <p>+54 9 11 6073-6020</p>
        <p>+54 9 11 6967-4273</p>
    </div>
  )
}

export default Telefono