import React from 'react'

function TablaHead() {
  return (
    <thead>
        <tr>
            <th>Artículo</th>
            <th>Detalles</th>
            <th>Código</th>
            <th>Cantidad</th>
            <th>Borrar</th>
        </tr>
    </thead>
  )
}

export default TablaHead