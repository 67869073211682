import React from 'react'

function Banner() {
  return (
    <div className='banner'>
        <img src="img/Banners/Checkout.webp" alt="checkout banner" />
        <h5>Gracias</h5>
        <p>por elegirnos</p>
    </div>
  )
}

export default Banner