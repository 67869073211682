import React from 'react'

function FormPais() {
  return (
    <div>
        <label htmlFor="pais">País</label>
        <input type="text" id='pais' name='pais'/>
    </div>
  )
}

export default FormPais